import React from "react";
import {
  Text,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Progress,
  Box,
} from "@chakra-ui/react";
import "../modal.style.scss";
import imgLoading from "../../../assets/yamalube_loading.gif"

const ModalLoading = ({ isOpen, onClose, size = "sm", isError = false }) => {
  const [overlay, setOverlay] = React.useState( <ModalOverlay
    bg='none'
    backdropFilter='auto'
    backdropInvert='80%'
    backdropBlur='2px'
  />)

  return (
    <Modal
      closeOnOverlayClick={false}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
      size={size}
      bg="transparent"
    >
      <ModalOverlay />
      <ModalContent bg="transparent">
        <ModalBody bg={"transparent"}>
          {isError ? (
            <Flex
              w="100%"
              flexDirection="column"
              justify="center"
              align="center"
            >
              <Text
                w={"100%"}
                textAlign={"center"}
                fontWeight="500"
                fontSize={{
                  base: "xl",
                  md: "xl",
                }}
                color={"primary.base"}
              >
                Ada masalah dengan koneksi interneet anda, pastikan koneksi
                internet anda berjalan lancar.
              </Text>
            </Flex>
          ) : (
            <Flex
              w="100%"
              flexDirection="column"
              justify="center"
              align="center"
            >
              {/* <Text
                w={"100%"}
                textAlign={"center"}
                fontWeight="500"
                fontSize={{
                  base: "xl",
                  md: "xl",
                }}
                color={"primary.base"}
              >
                Please wait, loading...
              </Text>
              <Progress
                my={3}
                w="100%"
                isIndeterminate
                colorScheme="red"
                size="sm"
              /> */}

              <Box>
                <img src={imgLoading} />
              </Box>
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalLoading;

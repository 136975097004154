import { Image } from "@chakra-ui/react";

const UILogoPart = ({
  pathDot = "."
}) => {
  return (
    <Image
      w={{ base: "150px", md: "200px", lg: "200px" }}
      p={2}
      objectFit="contain"
      src={`${pathDot}/assets/images/genuine-logo-event.jpg`}
      alt="logo yamalube"
      fallback={<div/>}
    />
  );
};

export default UILogoPart;

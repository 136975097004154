import {
  Box,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import servicesUser from "../../../services/services-user";
import userCookies from "../../../utils/user.cookies";
import ModalInfo from "../info/info.modal";
import ModalLoading from "../loading/loading.modal";
import LoginGoogleButton from "./login-google-button";
import "../modal.style.scss";
import { useParams } from "react-router-dom";

const ModalLogin = ({
  userData,
  isOpen,
  onClose,
  size = "lg",
  fromQR = true,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [googleIsLoad, setGoogleIsLoad] = useState(false);

  const modalLoading = useDisclosure();
  const modalInfo = useDisclosure();
  const [info, setInfo] = useState(null);
  const handleGoogleLogin = (user) => {
    userLogin(user._profile);
  };

  const handleGoogleLoginFailure = (err) => {
    console.error(err);
  };

  useEffect(() => {
    if (location.search.includes("mode")) {
      let mode = location.search.split("?mode=")[1];

      if(mode === "demo")
      userCookies.setCookiesDemo(mode);
      else
      userCookies.removeCookiesDemo();
    }
    else{
      userCookies.removeCookiesDemo();
    }
  }, [location]);

  const checkDemo = () => {
    let val = userCookies.getCookiesDemo();

    if (val === "demo") {
      return true;
    } else {
      userCookies.removeCookiesDemo();
      return false;
    }
  };

  const userLogin = async (user) => {
    modalLoading.onOpen();
    const response = await servicesUser.userLogin({
      email: user.email,
      name: user.name,
      provider: "google",
      provider_id: user.id,
      type: "mechanic",
    });
    if (response?.meta?.code === 200) {
      userCookies.setCookiesFirstLogin("true");
      userCookies.setCookiesToken(
        response.data.access_token,
        response.data.token_type
      );
      userData?.mutate();
      modalLoading.onClose();
      onClose();
    } else {
      userRegister(user);
    }
  };

  const userRegister = async (user) => {
    const response = await servicesUser.userRegister({
      email: user.email,
      name: user.name,
      provider: "google",
      type: "mechanic",
      provider_id: user.id,
    });
    if (response?.meta?.code === 200) {
      userCookies.setCookiesToken(
        response.data.access_token,
        response.data.token_type
      );
      userData?.mutate();
      modalLoading.onClose();
      onClose();
    } else {
      modalLoading.onClose();
      onClose();
      setInfo({
        status: "error",
        message: response?.data?.message || response?.message,
        messageDetail: "",
      });

      modalInfo.onOpen();
    }
  };

  const loginYamahaAccount = () => {
    onClose();
    history.push("/login");
  };

  return (
    <Fragment>
      <ModalInfo
        status={info?.status}
        message={info?.message}
        messageDetail={info?.messageDetail}
        isOpen={modalInfo?.isOpen}
        onClose={modalInfo?.onClose}
      />
      <ModalLoading
        isOpen={modalLoading?.isOpen}
        onClose={modalLoading?.onClose}
      />
      <Modal
        closeOnOverlayClick={false}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size={size}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Image
              w="100%"
              objectFit="contain"
              src="../assets/images/pattern7.png"
              alt=""
              position={"absolute"}
              top={0}
              left={0}
              zIndex={0}
            />
            <Image
              w="100%"
              objectFit="contain"
              src="../assets/images/pattern6.png"
              alt=""
              position={"absolute"}
              bottom={0}
              left={0}
              zIndex={0}
            />
            <Flex
              position={"relative"}
              zIndex={2}
              flexDirection="column"
              justify="center"
              align="center"
              py={12}
            >
              <Fragment>
                <Text
                  w={"80%"}
                  color={"gray"}
                  textAlign={"center"}
                  fontWeight="700"
                  fontSize={{
                    base: "xl",
                    md: "2xl",
                  }}
                >
                  Anda Ingin Login/Register Sebagai Apa?
                </Text>

                <Flex
                  flexDirection={"row"}
                  experimental_spaceX={{
                    base: 4,
                    md: 12,
                  }}
                  mt={10}
                  mb={4}
                >
                  {
                    <Flex
                      p={5}
                      h={{
                        base: "120px",
                        md: "140px",
                      }}
                      w={{
                        base: "120px",
                        md: "140px",
                      }}
                      boxShadow="lg"
                      border="2px"
                      borderColor="#CAB28B"
                      borderRadius={"md"}
                      justify="space-between"
                      align="center"
                      cursor="pointer"
                      flexDirection={"column"}
                      bg="white"
                      onClick={() => loginYamahaAccount()}
                    >
                      <Image
                        w={{
                          base: "45px",
                          md: "65px",
                        }}
                        h={{
                          base: "45px",
                          md: "65px",
                        }}
                        objectFit="contain"
                        src="./assets/images/icon_user.png"
                        alt=""
                      />
                      <Text
                        w="100%"
                        fontSize={{
                          base: "sm",
                          md: "md",
                        }}
                        color={"black"}
                        fontWeight="700"
                        textAlign="center"
                      >
                        Konsumen
                      </Text>
                    </Flex>
                  }

                  {checkDemo() === false && (
                    <>
                      {!googleIsLoad && (
                        <Box mt={4}>
                          <Spinner />
                        </Box>
                      )}
                      <LoginGoogleButton
                        setGoogleIsLoad={setGoogleIsLoad}
                        provider="google"
                        appId={process.env.REACT_APP_GOOGLE_KEY}
                        onLoginSuccess={handleGoogleLogin}
                        onLoginFailure={handleGoogleLoginFailure}
                      />
                    </>
                  )}
                </Flex>
              </Fragment>
            </Flex>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default ModalLogin;

import React, { Fragment } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Box, Flex, Text, Button, useDisclosure } from "@chakra-ui/react";
import UILogo from "../../ui/logo/logo.ui";
import ModalLogin from "../../modals/login/login.modal";
import userCookies from "../../../utils/user.cookies";
import { PathRoute } from "../../../constant/route.constant";
import { isDesktop } from "react-device-detect";
import ModalBlockDesktop from "../../modals/block-desktop/block-desktop.modal";

const MenuItems = (props) => {
  const { children, isLast, to = "/", ...rest } = props;
  return (
    <Text
      mb={{ base: isLast ? 0 : 8, sm: 0 }}
      mr={{ base: 0, sm: isLast ? 0 : 8 }}
      display="block"
      fontWeight="700"
      textTransform={"uppercase"}
      {...rest}
    >
      {to === "func" ? children : <Link to={to}>{children}</Link>}
    </Text>
  );
};

const SectionHeader = ({ user, ...props }) => {
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const modalBlockDesktop = useDisclosure();

  const menu = [
    { name: "Home", link: "/home" },
    { name: "Our Products", link: "/our-products" },
    { name: "Terms & Condition", link: "/terms-condition" },
    { name: "FAQ", link: "/faq" },
  ];

  const handleLogout = () => {
    userCookies.removeCookiesToken();
    user?.mutate();
    window.location.reload();
  };

  const handleOpen = () => {
    if (!isDesktop) {
      onOpen();
    } else {
      modalBlockDesktop.onOpen();
    }
  };

  return (
    <Fragment>
      <ModalBlockDesktop isOpen={modalBlockDesktop.isOpen} onClose={modalBlockDesktop.onClose} />
      <ModalLogin isOpen={isOpen} onClose={onClose} userData={user} />
      <Flex
        as="nav"
        align="center"
        justify={{ sm: "center", md: "center", lg: "space-between" }}
        wrap="wrap"
        maxW={{ xl: "1400px" }}
        w="100%"
        bg={["black"]}
        mt={4}
        mb={4}
        pl={8}
        pr={8}
        {...props}
      >
        <Flex align="center" w={["100%", "100%", "unset"]} justify={"center"}>
          <UILogo />
        </Flex>

        {/* NAV DESKTOP */}
        <Fragment>
          <Box
            display={{ base: "none", lg: "block" }}
            flexBasis={{ base: "100%", lg: "auto" }}
          >
            <Flex
              align={["center", "center", "center", "center"]}
              justify={["center", "space-between", "flex-end", "flex-end"]}
              direction={["column", "row", "row", "row"]}
              pt={[4, 4, 0, 0]}
            >
              {menu.map((item, key) => (
                <MenuItems
                  _hover={{ color: "primary.active" }}
                  color={
                    location.pathname === item.link ||
                    (location.pathname === "/" && item.link === "/home")
                      ? "primary.active"
                      : "white"
                  }
                  key={`menu_desktop_${key}`}
                  to={item.link}
                >
                  {item.name}
                </MenuItems>
              ))}
            </Flex>
          </Box>
          <Box
            display={{ base: "none", lg: "block" }}
            flexBasis={{ base: "100%", lg: "auto" }}
          >
            <Flex
              align={["center", "center", "center", "center"]}
              justify={["center", "space-between", "flex-end", "flex-end"]}
              direction={["column", "row", "row", "row"]}
              pt={[4, 4, 0, 0]}
            >
              {!user?.data?.member_number ? (
                <MenuItems to="func" isLast>
                  <Button
                    pt={6}
                    pr={4}
                    pb={6}
                    pl={4}
                    size="sm"
                    rounded="md"
                    color={"white"}
                    bg={"primary.base"}
                    _hover={{
                      bg: "primary.hover",
                    }}
                    onClick={() => handleOpen()}
                  >
                    Masuk / Daftar
                  </Button>
                </MenuItems>
              ) : user?.data?.type === null ? (
                <MenuItems to="func" isLast>
                  <Flex justify="center" align="center">
                    <Button
                      pt={6}
                      pr={4}
                      pb={6}
                      pl={4}
                      size="sm"
                      rounded="md"
                      color={"white"}
                      bg={"primary.base"}
                      _hover={{
                        bg: "primary.hover",
                      }}
                      onClick={() => handleLogout()}
                    >
                      Logout
                    </Button>
                  </Flex>
                </MenuItems>
              ) : (
                <MenuItems to="func" isLast>
                  <Flex justify="center" align="center">
                    <Text
                      mr={{ base: 4, sm: 4 }}
                      display="block"
                      fontWeight="700"
                      color={"white"}
                    >
                      {user?.data?.type !== null && (
                        <MenuItems to="/profile" isLast>
                          Hi, {user?.data?.name}
                        </MenuItems>
                      )}
                    </Text>
                    {location.pathname === PathRoute.PATH_PROFILE ? (
                      <Button
                        pt={6}
                        pr={4}
                        pb={6}
                        pl={4}
                        size="sm"
                        rounded="md"
                        color={"white"}
                        bg={"primary.base"}
                        _hover={{
                          bg: "primary.hover",
                        }}
                        onClick={() => handleLogout()}
                      >
                        Logout
                      </Button>
                    ) : (
                      <Button
                        pt={6}
                        pr={4}
                        pb={6}
                        pl={4}
                        size="sm"
                        rounded="md"
                        color={"white"}
                        bg={"primary.base"}
                        _hover={{
                          bg: "primary.hover",
                        }}
                        onClick={() => history.push("/profile")}
                      >
                        Profile
                      </Button>
                    )}
                  </Flex>
                </MenuItems>
              )}
            </Flex>
          </Box>
        </Fragment>
      </Flex>
    </Fragment>
  );
};

export default SectionHeader;

import useSWR from 'swr';
import servicesKatalog from '../services/services-katalog';

const servGetDataKatalog = url => servicesKatalog.getKatalog(url);

const useDataKatalog = (slug, pageNo, category, name) => {
  const dataKatalog = useSWR(`/catalog/product/${slug}?page=${pageNo}${category && category?.id !== 0 ? '&category='+category.id : ''}${name && name !== "" ? '&name='+name : ''}`, servGetDataKatalog, {revalidateOnFocus: false})
  return {
    data: dataKatalog?.data?.data,
    isLoading: !dataKatalog?.error && !dataKatalog?.data?.data,
    isError: dataKatalog?.error,
  };
};

export default useDataKatalog;




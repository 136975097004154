import useSWR from 'swr';
import servicesDistrict from '../services/services-district';

const servGetDistrictByIdCity = url => servicesDistrict.getDistrictByIdCity(url);

const useDistrict = (idCity) => {
  const district = useSWR(`/district/${idCity}`, servGetDistrictByIdCity, {revalidateOnFocus: false})

  return {
    data: district?.data?.data,
    isLoading: !district?.error && !district?.data?.data,
    isError: district?.error,
  };
};

export default useDistrict;

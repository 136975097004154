import React, { Fragment } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Box, Flex, Text, Button, useDisclosure } from "@chakra-ui/react";
import UILogo from "../../ui/logo/logo.ui";
import UILogoYamaha from "../../ui/logo/logo-yamaha.ui";
import UILogoBlack from "../../ui/logo/logo-black.ui";
import UILogoBlackVoucher from "../../ui/logo/logo-black-voucher.ui";
import UILogoGenuine from "../../ui/logo/logo-genuine.ui";

const SectionHeaderVoucher = ({ ...props }) => {
  return (
    <Fragment>
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        w="100%"
        bg={["black"]}
        pt={8}
        mt={0}
        mb={4}
        pl={{
          base: "4",
          md: "8",
        }}
        pr={{
          base: "4",
          md: "8",
        }}
        {...props}
      >
        <Flex align="center" w={["100%", "100%", "unset"]} justify={"center"}>
          <UILogoBlackVoucher />
        </Flex>

        <Flex align="center" w={["100%", "100%", "unset"]} justify={"center"}>
          <UILogoGenuine />
        </Flex>
      </Flex>
    </Fragment>
  );
};

export default SectionHeaderVoucher;

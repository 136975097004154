import React, { useState } from "react";
import { FormControl, FormLabel, Input } from "@chakra-ui/react";
import UIFormTextLabel from "./text-label.ui";
import UIFormTextError from "./text-error.ui";
import helper from "../../../utils/helper";

const UIFormInputFile = ({
  type = "text",
  defaultValue = "",
  name,
  label,
  labelExtend,
  placeholder = "",
  register,
  textError = "",
  isInvalid,
  isRequired = false,
  isDisabled = false,
  mt = {
    base: "4",
    md: "6",
  },
  h = "50px",
  color="text.input",
  fontWeight="400",
  colorLabel="white"
  
}) => {
  const [textErrorValidasi, setTextErrorValidasi] = useState(textError);



  return (
    <FormControl mt={mt}>
      {label &&
      (
        <FormLabel>
          <UIFormTextLabel color={colorLabel} label={label} isRequired={isRequired} labelExtend={labelExtend} />
        </FormLabel>
      )}
      <Input
      bg="white"
        h={h}
        fontWeight={fontWeight}
        type={type}
        color={color}
        placeholder={placeholder}
        isInvalid={isInvalid}
        errorBorderColor="primary.base"
        isDisabled={isDisabled}
        _disabled={{ backgroundColor: "#F5F5F5" }}
        {...register(name, {
          required: isRequired ? true : false,
        })}
        style={{
         
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding:" 10px"}}
      />

      <UIFormTextError text={textErrorValidasi} isError={isInvalid} />
    </FormControl>
  );
};

export default UIFormInputFile;

import API from "./http-common";

const getKatalog = (url) => {
  return API.http()
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return e.response.data;
    });
};


export default {
  getKatalog
};


import API from "./http-common";

const userLogin = (payload) => {
  return API.http()
    .post("/signin", payload)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return e.response.data;
    });
};

const userRegister = (payload) => {
  return API.http()
    .post("/signup", payload)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return e.response.data;
    });
};

const userGetMe = (url) => {
  return API.http()
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return e.response;
    });
};

const userUpdateProfile = (payload) => {
  return API.http()
    .post("/update-profile", payload)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return e.response.data;
    });
};

export default {
    userLogin,
    userRegister,
    userGetMe,
    userUpdateProfile
};

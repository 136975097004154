import React, { useState } from "react";
import { FormControl, FormLabel, Input, Text } from "@chakra-ui/react";
import UIFormTextLabel from "./text-label.ui";
import UIFormTextError from "./text-error.ui";
import helper from "../../../utils/helper";

const UIFormInput = ({
  type = "text",
  defaultValue = "",
  name,
  label,
  sublabel,
  labelExtend,
  placeholder = "",
  register,
  textError = "",
  isInvalid,
  isRequired = false,
  isDisabled = false,
  mt = {
    base: "4",
    md: "6",
  },
  h = "50px",
  color = "text.input",
  colorLabel = "white",
  fontWeight = "400",
  isKtpNumber = false,
}) => {
  const [textErrorValidasi, setTextErrorValidasi] = useState(textError);

  const validasiInput = (text) => {
    if (helper.validasiTagHtml(text)) {
      setTextErrorValidasi(textError);
      return true;
    } else {
      setTextErrorValidasi("Tidak boleh mengandung syntax code");
      return false;
    }
  };

  const validasiNumberKTP = (text) => {
    if (text.length !== 16) {
      setTextErrorValidasi(textError);
      return true;
    } else {
      setTextErrorValidasi("Nomor KTP harus 16 digit");
      return false;
    }
  };

  return (
    <FormControl mt={mt}>
      {label && (
        <FormLabel>
          <UIFormTextLabel
            color={colorLabel}
            label={label}
            isRequired={isRequired}
            labelExtend={labelExtend}
          />
        </FormLabel>
      )}

      {sublabel && <Text color={"#303030"} fontSize={"xs"} mb={4}>{sublabel}</Text>}

      <Input
        h={h}
        fontWeight={fontWeight}
        type={type}
        color={color}
        defaultValue={defaultValue}
        placeholder={placeholder}
        isInvalid={isInvalid}
        errorBorderColor="primary.base"
        isDisabled={isDisabled}
        bg="white"
        _disabled={{ backgroundColor: "white" }}
        {...register(name, {
          required: isRequired ? true : false,
          validate: validasiInput,
        })}
      />

      <UIFormTextError text={textErrorValidasi} isError={isInvalid} />
    </FormControl>
  );
};

export default UIFormInput;

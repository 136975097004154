import React, { Fragment } from "react";
import {
  Text
} from "@chakra-ui/react";

const UIFormTextError = ({ text, isError = false, mt="2" }) => {
    return (
      <Fragment>
        {isError && (
          <Text fontSize="sm" fontWeight="500" color="primary.base" mt={mt}>
            {text}
          </Text>
        )}
      </Fragment>
    );
  };

export default UIFormTextError;
import API from "./http-common";
import API_PROD from "./http-prod";

const codeCheck = (code, email) => {
  let payload = {
    uniq_code: code,
  };

  if(email){
    payload.email = email;
  }

  return API.http()
    .post("/check-code", payload)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return e.response.data;
    });
};

const codeRedeem = (code) => {
  let payload = {
    uniq_code: code,
    type: 'undian'
  };

  return API.http()
    .post("/reedem-code", payload)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return e.response.data;
    });
};

const codeCheckRedeem = (code) => {
  let payload = {
    uniq_code: code,
  };

  return API.http()
    .post("/validasi-redeem", payload)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return e.response.data;
    });
};

const getRedeemHistory = (url) => {
  return API.http()
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return false;
    });
};

const claimReward = (payload) => {
  return API.http()
    .post("/claim-reward", payload)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return e.response.data;
    });
};

const updateReferral = (code) => {
  let payload = {
    referral_code: code,
  };

  return API.http()
    .post("/update-referral-code", payload)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return e.response.data;
    });
};

const uplaodFileKTP = (payload) => {
  return API.httpFile()
    .post("/upload-ktp", payload)
    .then((res) => {
      return res?.data;
    })
    .catch((e) => {
      return e?.response?.data;
    });
};

const uplaodFileKTPOCR = (payload) => {
  return API.httpFile()
    .post("/upload-ktp-ocr", payload)
    .then((res) => {
      return res?.data;
    })
    .catch((e) => {
      return e?.response?.data;
    });
};

const uplaodFileKTPOCRProd = (payload) => {
  return API_PROD.httpFile()
    .post("/upload-ktp-ocr", payload)
    .then((res) => {
      return res?.data;
    })
    .catch((e) => {
      return e?.response?.data;
    });
};

export default {
  codeCheckRedeem,
  codeCheck,
  codeRedeem,
  getRedeemHistory,
  claimReward,
  updateReferral,
  uplaodFileKTP,
  uplaodFileKTPOCR,
  uplaodFileKTPOCRProd
};

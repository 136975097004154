import React, { useState } from "react";
import { FormControl, FormLabel, Textarea, Text } from "@chakra-ui/react";
import UIFormTextLabel from "./text-label.ui";
import UIFormTextError from "./text-error.ui";
import helperUtils from "../../../utils/helper";

const UIFormTextArea = ({
  name,
  label,
  placeholder = "",
  register,
  textError = "",
  isInvalid,
  helper,
  isRequired = false,
  isDisabled = false,
  mt = {
    base: "4",
    md: "6",
  },
  h = {
    base: "50px",
    md: "50px",
  },
  bg = "white",
  colorLabel = "white",
}) => {
  const [textErrorValidasi, setTextErrorValidasi] = useState(textError);

  const validasiInput = (text) => {
    if (helperUtils.validasiTagHtml(text)) {
      setTextErrorValidasi(textError);
      return true;
    } else {
      setTextErrorValidasi("Tidak boleh mengandung syntax code");
      return false;
    }
  };

  return (
    <FormControl mt={mt}>
      <FormLabel>
        <UIFormTextLabel
          color={colorLabel}
          label={label}
          isRequired={isRequired}
        />{" "}
        {helper && <Text color="gray.400">{helper}</Text>}
      </FormLabel>

      <Textarea
        h={h}
        bg={bg}
        color="text.input"
        isInvalid={isInvalid}
        placeholder={placeholder}
        isDisabled={isDisabled}
        _disabled={{ backgroundColor: "#F5F5F5" }}
        {...register(name, {
          required: isRequired ? true : false,
          validate: validasiInput,
        })}
      />
      <UIFormTextError text={textErrorValidasi} isError={isInvalid} />
    </FormControl>
  );
};

export default UIFormTextArea;

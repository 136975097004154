import useSWR from "swr";
import cookies from "js-cookie";
import { useEffect } from "react";

import servicesUser from "../services/services-user";
const servGetUserMe = (url) => servicesUser.userGetMe(url);

const useUser = () => {
  const { data, mutate, error } = useSWR("/me", servGetUserMe, {
    revalidateOnFocus: false,
  });

  const isLoading = !data?.data;
  const isError = error;

  return {
    data: data?.data,
    isLoading,
    isError,
    mutate,
  };
};

export default useUser;

import React from "react";
import { Text, Flex, Image } from "@chakra-ui/react";
import SocialLogin from "react-social-login";

class LoginGoogleButton extends React.Component {
  render() {
    const { triggerLogin, setGoogleIsLoad, ...props } = this.props;

    return (
      <Flex
        ref={(c) => {
          setGoogleIsLoad(true);
        }}
        p={5}
        h={{
          base: "120px",
          md: "140px",
        }}
        w={{
          base: "120px",
          md: "140px",
        }}
        boxShadow="lg"
        border="2px"
        borderColor="#CAB28B"
        borderRadius={"md"}
        justify="space-between"
        align="center"
        cursor="pointer"
        flexDirection={"column"}
        onClick={triggerLogin}
        bg="white"
        {...props}
      >
        <Image
          w={{
            base: "45px",
            md: "65px",
          }}
          h={{
            base: "45px",
            md: "65px",
          }}
          objectFit="contain"
          src="./assets/images/icon_mekanik.png"
          alt=""
          fallback={<div />}
        />
        <Text
          w="100%"
          color={"black"}
          fontSize={{
            base: "sm",
            md: "md",
          }}
          fontWeight="700"
          textAlign="center"
        >
          Mekanik
        </Text>
      </Flex>
    );
  }
}

export default SocialLogin(LoginGoogleButton);

import React, { Fragment } from "react";
import { Image } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { PathRoute } from "../../../constant/route.constant";

const UILogoBlackVoucher = () => {
  const history = useHistory();
 
  return (
    <Image
      w={{ base: "150px", md: "200px", lg: "200px" }}
      objectFit="contain"
      src="../assets/images/logo-black.svg"
      alt="logo yamalube"
      fallback={<div/>}
      onClick={() => history.push(PathRoute.PATH_HOME)}
      cursor="pointer"
    />
  );
};

export default UILogoBlackVoucher;

import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import YamalubeTheme from "./yamalube.theme";

import ScrollToTop from "./utils/ScrollToTop";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename="/">
      <ScrollToTop />
      <ChakraProvider theme={YamalubeTheme}>
        <App />
      </ChakraProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

import { Flex, Text } from "@chakra-ui/react";
import { Fragment } from "react";
import { useLocation, useParams } from "react-router-dom";
import useDataKatalog from "../../../hook/hook-katalog";
import UILogoBlack from "../../ui/logo/logo-black.ui";
import UILogoPart from "../../ui/logo/logo-part";

const SectionHeaderKatalog = ({ ...props }) => {
  const location = useLocation();
 
  const { slug } = useParams();
  const katalog = useDataKatalog(location.pathname.split('/')[location.pathname.split('/').length-1], 1, null);

  return (
    <Fragment>
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        w="100%"
        bg={["white"]}
        pt={8}
        mt={0}
        mb={4}
        pl={{
          base: "0",
          md: "2",
        }}
        pr={{
          base: "4",
          md: "8",
        }}
        borderBottom="8px solid #FA0606"
        {...props}
      >
        <Flex align="center" w={["100%", "100%", "unset"]} justify={"center"}>
          <UILogoPart pathDot=".." />
        </Flex>

        <Flex display={{base: 'none', md: 'flex'}}>
          <Text fontSize="3xl" fontWeight="bold" color={"black"}>
            {katalog?.data?.event
              ? "KATALOG " + katalog?.data?.event.name
              : "KATALOG"}
          </Text>
        </Flex>

        <Flex align="center" w={["100%", "100%", "unset"]} justify={"center"}>
          <UILogoBlack pathDot=".." />
        </Flex>
      </Flex>
    </Fragment>
  );
};

export default SectionHeaderKatalog;

import useSWR from 'swr';
import servicesSetting from '../services/services-setting';

const servGetSetting = url => servicesSetting.getSetting(url);

const useSetting = () => {
  const setting = useSWR(`/setting`, servGetSetting, {revalidateOnFocus: false})

  return {
    data: setting?.data?.data,
    isLoading: !setting?.error && !setting?.data?.data,
    isError: setting?.error,
  };
};

export default useSetting;
